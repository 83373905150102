import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Use cases',
  description: 'Uploadcare provides universal experience and fits lots of use cases. We help our customers achieve their business goals and bring more value to their clients.'
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CallToAction = makeShortcode("CallToAction");
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <style>
  {`
    .col {
      float: left;
      box-sizing: border-box;
      padding: 0 16px;
    }
    .col-6 {
      width: 50%;
    }
    .illustrated {
      border-bottom: solid 1px #e0e1e1;
      margin-bottom: 21px;
      position: relative;
    }
    .illustrated h2 {
      line-height: 1.4;
      font-size: 24px;
    }
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button,
    .button:visited {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      border: 0;
      margin: 0;
      padding: 0;
      border-radius: 100px;
      height: 60px;
      line-height: 60px;
      padding: 10px 16px;
      font-size: 20px;
      text-align: center;
      color: #191919;
      background: #ffd800;
      cursor: pointer;
    }
    .button:hover,
    .button:visited:hover {
      color: #191919;
      background-color: #fef33c;
    }

    @media (max-width: 768px) {
      .row {
        flex-direction: column;
      }
      .col-text {
        order: 2;
      }
      .col-image {
        order: 1;
        text-align: center;
      }
    }
  `}
    </style>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/avatars.6fd56a4daced.png" />
    </span>
    <span className="col col-6 col-text">
      <h2>Avatars</h2>
      <p>
        The time when enabling people to use an avatar from Facebook
        was bothersome is long gone.
      </p>
      <p>
        In less than an hour, enable your user to choose his avatar from any
        social network or even URL. Uploads from computer became more comfortable
        too, with drag-n-drop and progress bar.
      </p>
    </span>
  </div>
    </div>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-text">
      <h2>Blogs</h2>
      <p>
        Adding a picture to a blog post is now much simpler, if you don’t have to
        save an image on your computer and resize it manually before uploading.
      </p>
      <p>
        For those who likes to have many images in their blogs, the fast CDN on modern
        technologies is going to provide a nice improvement to the experience
        of their readers.
      </p>
    </span>
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/blogs.6830500d396a.png" />
    </span>
  </div>
    </div>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/document_flow.de8746b93a4c.png" />
    </span>
    <span className="col col-6 col-text">
      <h2>Document flow</h2>
      <p>Uploadcare can be of help for internal document flow systems.</p>
      <p>
        Attaching files to the discussion should not be a
        complicated process, as well as uploading images doesn’t have
        to take much time when you’re writing something important
        in WISIWYG.
      </p>
    </span>
  </div>
    </div>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-text">
      <h2>Communities</h2>
      <p>If you’re working on online community, the usability of uploading
        photographs is really important. Using Uploadcare, you can focus on
        important things, while we handle stuff like hosting and image resizing.
      </p>
      <p>
        And you will never have to put up with fixed size of an image anymore.
        Whatever size you need, it’s at your service&nbsp;
        <a href="/documentation/cdn/">right when you need it</a>.
      </p>
    </span>
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/communities.ba7034c28ff1.png" />
    </span>
  </div>
    </div>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/cms.f1f33905dbad.png" />
    </span>
    <span className="col col-6 col-text">
      <h2>CMS</h2>
      <p>If your website is based on CMS, is its Drupal or even
        something custom on Django, Rails, PHP or anything else,
        Uploadcare can be integrated with no hassle &mdash; and save
        you all the troubles related to the files. Your content managers
        are going to thank you.</p>
      <p>
        Take a look at&nbsp;<a href="/documentation/">our integrations list</a>
        &nbsp;or&nbsp;<a href="/about/">drop us a line</a> if you have something
        we’re not working with yet.
        </p>
    </span>
  </div>
    </div>
    <div className="illustrated">
  <div className="row">
    <span className="col col-6 col-text">
      <h2>Bootstrapped Startups</h2>
      <p>
        If you’re building a proof-of-concept in a fast-changing environment,
        the last thing you want to do is to bother about file hosting.
      </p>
      <p>
        Use our free plan to start your own adventure.
        Our goal is to help your endeavours, and we will be&nbsp;
        <a href="/about/">happy to hear</a>&nbsp;what you are up to and how
        we can help you more.
      </p>
    </span>
    <span className="col col-6 col-image">
      <img src="https://ucarecdn.com/assets/images/use-cases/bootstrapped_startups.ea335e55270c.png" />
    </span>
  </div>
    </div>
    <p>{`If you like the simplicity, signup to start using Uploadcare.
And don’t hesitate to`}{` `}</p>
    <a className="js-support-link" href="mailto:hello@uploadcare.com">contact us</a>
&nbsp;with any questions, suggestions or exclamations, if you feel like that.
    <br />
    <br />
    <p>{`The Uploadcare Team :-)`}</p>
    <br />
    <br />
    <CallToAction text='Sign up' to='/accounts/create/free/' mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      